export default {
  title: 'Conferma Iscrizione Azienda',
  notAllParams: 'Alcuni parametri richiesti sono mancanti.',
  description:
    'Sei stato invitato a confermare la nuova iscrizione aziendale per {company}. La richiesta è a nome di {firstName} {lastName}, CAP: {zipcode}, Email: {email}. Si prega di confermare qui sotto.',
  thankYou: 'Grazie per la tua decisione.',
  changeDecision: 'Cambia Decisione',
  approve: 'Approva',
  reject: 'Rifiuta',
  modal: {
    approve: {
      title: 'Approva Iscrizione',
      subtitle: 'Vuoi davvero approvare questa iscrizione?',
      confirm: 'Approva'
    },
    revert: {
      title: 'Annulla Decisione',
      subtitle: 'Vuoi cambiare la tua decisione?',
      confirm: 'Annulla'
    },
    reject: {
      title: 'Rifiuta Iscrizione',
      subtitle: 'Vuoi davvero rifiutare questa iscrizione?',
      confirm: 'Rifiuta'
    }
  }
};
