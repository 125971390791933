export default {
  title: 'Confirm Company Signup',
  notAllParams: 'Some required parameters are missing.',
  description:
    'You have been invited to confirm the new company signup for {company}. The request is under the name of {firstName} {lastName}, Zip: {zipcode}, Email: {email}. Please confirm below.',
  thankYou: 'Thank you for your decision.',
  changeDecision: 'Change Decision',
  approve: 'Approve',
  reject: 'Reject',
  modal: {
    approve: {
      title: 'Approve Signup',
      subtitle: 'Do you really want to approve this signup?',
      confirm: 'Approve'
    },
    revert: {
      title: 'Revert Decision',
      subtitle: 'Do you want to change your decision?',
      confirm: 'Revert'
    },
    reject: {
      title: 'Reject Signup',
      subtitle: 'Do you really want to reject this signup?',
      confirm: 'Reject'
    }
  }
};
