export default {
  title: 'Unternehmensanmeldung bestätigen',
  notAllParams: 'Einige erforderliche Parameter fehlen.',
  description:
    'Sie wurden eingeladen, die neue Unternehmensanmeldung für {company} zu bestätigen. Die Anfrage erfolgt im Namen von {firstName} {lastName}, PLZ: {zipcode}, E-Mail: {email}. Bitte bestätigen Sie unten.',
  thankYou: 'Danke für Ihre Entscheidung.',
  changeDecision: 'Entscheidung ändern',
  approve: 'Genehmigen',
  reject: 'Ablehnen',
  modal: {
    approve: {
      title: 'Anmeldung genehmigen',
      subtitle: 'Möchten Sie diese Anmeldung wirklich genehmigen?',
      confirm: 'Genehmigen'
    },
    revert: {
      title: 'Entscheidung rückgängig machen',
      subtitle: 'Möchten Sie Ihre Entscheidung ändern?',
      confirm: 'Rückgängig machen'
    },
    reject: {
      title: 'Anmeldung ablehnen',
      subtitle: 'Möchten Sie diese Anmeldung wirklich ablehnen?',
      confirm: 'Ablehnen'
    }
  }
};
