import { useCallback, useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Jungle from '@/assets/jungle-1.jpg';
import { Button, Heading, Paragraph, Spinner } from '@/components/atoms';
import { AuthForm, Modal } from '@/components/molecules';
import { useIntl } from '@/intl';
import environmentService from '@/services/environment.service';

const ConfirmCompanySignup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  // Data coming in via the query parameters:
  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const companyName = searchParams.get('companyName');
  const zipcode = searchParams.get('zipcode');
  const email = searchParams.get('email');

  // Because we might require all, let's do a quick check:
  const allParamsGiven =
    firstName && lastName && companyName && zipcode && email && token;

  const [action, setAction] = useState<'approve' | 'reject' | 'revert'>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSendRequest = useCallback(
    async (decision: 'approve' | 'reject') => {
      // if (!executeRecaptcha) {
      //   console.log('Recaptcha not available');
      //   return;
      // }
      //
      // const recaptchaToken = await executeRecaptcha('confirm_company_signup');
      // if (!recaptchaToken) {
      //   return;
      // }

      try {
        setError('');
        setLoading(true);
        // Using a GET request to /public/signup/company/confirm/admin/:token?decision=approve/reject
        if (!token) {
          throw new Error('No token provided.');
        }

        await axios.get(
          `${environmentService.baseUrl}/core/v1/public/signup/company/confirm/admin/${token}`,
          {
            params: {
              decision
            }
          }
        );
        setLoading(false);
        setSuccess(true);
        setShowModal(false);
      } catch (e: any) {
        setLoading(false);
        setError(
          e?.response?.data?.error ||
            formatMessage({ id: 'shared.errors.generic' })
        );
      }
    },
    [token, formatMessage]
  );

  const handleReject = () => {
    void handleSendRequest('reject');
  };

  const handleApprove = () => {
    void handleSendRequest('approve');
  };

  const revertDecision = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <AuthForm
        onSubmit={e => {
          e.preventDefault();
        }}
        imageSrc={Jungle}>
        <Heading type='h2'>
          {formatMessage({
            id: 'companySignupConfirm.title',
            defaultMessage: 'Confirm Company Signup'
          })}
        </Heading>

        {!success ? (
          <Paragraph>
            {!allParamsGiven
              ? formatMessage({
                  id: 'companySignupConfirm.notAllParams',
                  defaultMessage: 'Some required parameters are missing.'
                })
              : formatMessage(
                  {
                    id: 'companySignupConfirm.description',
                    defaultMessage:
                      'You have been invited to confirm the new company signup for {company}. ' +
                      'The request is under the name of {firstName} {lastName}, ' +
                      'Zip: {zipcode}, Email: {email}. ' +
                      'Please confirm below.'
                  },
                  {
                    company: companyName,
                    firstName,
                    lastName,
                    zipcode,
                    email: email
                    // optionally format date if you had any
                    // example: startDate: formatDate(startDate)
                  }
                )}
          </Paragraph>
        ) : (
          <Paragraph>
            {formatMessage({
              id: 'companySignupConfirm.thankYou',
              defaultMessage: 'Thank you for your decision.'
            })}
          </Paragraph>
        )}

        {success ? (
          <Button
            variant='gray'
            onClick={() => {
              setAction('revert');
              setShowModal(true);
            }}>
            {formatMessage({
              id: 'companySignupConfirm.changeDecision',
              defaultMessage: 'Change Decision'
            })}
          </Button>
        ) : (
          <div className='flex w-full flex-col gap-4 sm:flex-row sm:justify-center md:justify-start'>
            <Button
              variant='success'
              onClick={() => {
                setAction('approve');
                setShowModal(true);
              }}>
              {formatMessage({
                id: 'companySignupConfirm.approve',
                defaultMessage: 'Approve'
              })}
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setAction('reject');
                setShowModal(true);
              }}>
              {formatMessage({
                id: 'companySignupConfirm.reject',
                defaultMessage: 'Reject'
              })}
            </Button>
          </div>
        )}
      </AuthForm>

      <Modal
        title={
          action === 'approve'
            ? formatMessage({
                id: 'companySignupConfirm.modal.approve.title',
                defaultMessage: 'Approve Signup'
              })
            : action === 'revert'
            ? formatMessage({
                id: 'companySignupConfirm.modal.revert.title',
                defaultMessage: 'Revert Decision'
              })
            : formatMessage({
                id: 'companySignupConfirm.modal.reject.title',
                defaultMessage: 'Reject Signup'
              })
        }
        subtitle={
          action === 'approve'
            ? formatMessage({
                id: 'companySignupConfirm.modal.approve.subtitle',
                defaultMessage: 'Do you really want to approve this signup?'
              })
            : action === 'revert'
            ? formatMessage({
                id: 'companySignupConfirm.modal.revert.subtitle',
                defaultMessage: 'Do you want to change your decision?'
              })
            : formatMessage({
                id: 'companySignupConfirm.modal.reject.subtitle',
                defaultMessage: 'Do you really want to reject this signup?'
              })
        }
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setError('');
        }}
        onConfirm={() => {
          if (action === 'approve') {
            handleApprove();
          } else if (action === 'revert') {
            revertDecision();
          } else {
            handleReject();
          }
          if (success) {
            setShowModal(false);
          }
        }}
        confirmButtonText={
          action === 'approve'
            ? formatMessage({
                id: 'companySignupConfirm.modal.approve.confirm',
                defaultMessage: 'Approve'
              })
            : action === 'revert'
            ? formatMessage({
                id: 'companySignupConfirm.modal.revert.confirm',
                defaultMessage: 'Revert'
              })
            : formatMessage({
                id: 'companySignupConfirm.modal.reject.confirm',
                defaultMessage: 'Reject'
              })
        }
        confirmButtonVariant={
          action === 'approve'
            ? 'success'
            : action === 'revert'
            ? 'secondary'
            : 'danger'
        }
        noAction={loading}
        errorMessage={error}>
        {loading && (
          <div className='mx-auto pt-2'>
            <Spinner />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConfirmCompanySignup;
