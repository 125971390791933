export default {
  title: "Confirmer l'inscription de l'entreprise",
  notAllParams: 'Certains paramètres requis sont manquants.',
  description:
    "Vous avez été invité à confirmer la nouvelle inscription de l'entreprise pour {company}. La demande est au nom de {firstName} {lastName}, Code postal : {zipcode}, Email : {email}. Veuillez confirmer ci-dessous.",
  thankYou: 'Merci pour votre décision.',
  changeDecision: 'Changer de décision',
  approve: 'Approuver',
  reject: 'Rejeter',
  modal: {
    approve: {
      title: "Approuver l'inscription",
      subtitle: 'Voulez-vous vraiment approuver cette inscription ?',
      confirm: 'Approuver'
    },
    revert: {
      title: 'Annuler la décision',
      subtitle: 'Voulez-vous changer votre décision ?',
      confirm: 'Annuler'
    },
    reject: {
      title: "Rejeter l'inscription",
      subtitle: 'Voulez-vous vraiment rejeter cette inscription ?',
      confirm: 'Rejeter'
    }
  }
};
